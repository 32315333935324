import { Attribute } from '../clients/DiscoveryPagesService';
import { MsxProduct } from '../clients/MsxService';
import { ApplicationState } from '../model/ApplicationState';
import { Category } from '../model/Category';
import { AttributeFilter, CategoryFilter, ProductFilter, ScopeDefinition } from '../model/ScopeDefinition';

export const mapToScopeDefinition = (applicationState: ApplicationState): ScopeDefinition => {
  const scopeDefinition: ScopeDefinition = {
    default: {
      categories: applicationState.categories.map(mapCategory),
      products: applicationState.products.map(mapProduct),
      attributes: applicationState.attributes.map(mapAttribute),
      useOldScope: applicationState.useOldScope,
    },
  };
  return scopeDefinition;
};

const mapCategory = (category: Category): CategoryFilter => {
  return {
    categoryId: category.id,
  };
};

const mapProduct = (product: MsxProduct): ProductFilter => {
  return {
    key: 'mpvId',
    value: product.mpvId,
  };
};

const mapAttribute = (attribute: Attribute): AttributeFilter => {
  return {
    key: attribute.name,
    selectedValues: attribute.values,
  };
};
