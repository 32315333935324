import { Pill } from '@contentful/f36-components';
import React from 'react';
import { MsxProduct } from '../clients/MsxService';
import { TestIds } from '../fixtures/constants';

export const ProductPills = ({
  selectedProducts,
  handleRemoveProduct,
}: {
  selectedProducts: MsxProduct[];
  handleRemoveProduct: (product: MsxProduct) => void;
}): React.JSX.Element => {
  const handleOnPillClose = (product: MsxProduct) => {
    handleRemoveProduct(product);
  };

  return (
    <>
      {selectedProducts
        .sort((a, b) => a.mpvId.localeCompare(b.mpvId))
        .map((product) => (
          <Pill
            key={product.mpvId}
            data-test-id={TestIds.PRODUCT_PILL}
            label={product.mpvId}
            onClose={() => handleOnPillClose(product)}
          />
        ))}
    </>
  );
};
