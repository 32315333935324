import { AuthProvider as ContentfulAuthProvider } from '@vp/contentful-app/auth';
import React from 'react';

// The client ID for the Foundations accessing Treatment service
const AUTH_CLIENT_ID = 'CfxS4xKLB69FECDjff4uiG2gzviIAvXs';

export function AuthProvider(props: { children?: React.ReactNode }): JSX.Element {
  return (
    <ContentfulAuthProvider authClientId={AUTH_CLIENT_ID} authenticateLinkText="Login to view treatments">
      {props.children}
    </ContentfulAuthProvider>
  );
}
