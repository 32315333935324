import { configuration } from '../configuration';
import { ClientResult } from './ClientResult';

export interface AttributeMapping {
  attribute: MappedAttribute;
  values: MappedAttributeValue[];
}

export interface MappedAttribute {
  source: string;
  mapped: string;
}

export interface MappedAttributeValue {
  source: string;
  mapped: string;
}

export async function getAttributeMappings(): Promise<ClientResult<AttributeMapping[]>> {
  const url = `${configuration.urls.ATTRIBUTE_MAPPINGS_URL}v1/attribute-mapping?requestor=${configuration.requestor}`;
  const result: Response = await fetch(url);

  if (!result.ok) {
    return {
      failed: true,
      result: [],
    };
  }

  return {
    failed: false,
    result: (await result.json()) as AttributeMapping[],
  };
}
