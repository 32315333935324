import { TreeNode, TreeNodeProps } from 'react-dropdown-tree-select';
import { Category } from '../model/Category';

export const updateTreeNodeRecursive = (
  nodes: TreeNodeProps[],
  currentNode: TreeNodeProps,
  selectedCategories: Category[]
): TreeNodeProps[] => {
  return nodes.map((node: TreeNodeProps) => {
    if (node.value === currentNode.value) {
      if (currentNode.expanded) {
      }
      return {
        ...node,
        checked: selectedCategories.some((category) => category.id === node.value),
        expanded: currentNode.expanded,
      };
    }
    if (node.children && node.children.length > 0) {
      return {
        ...node,
        children: updateTreeNodeRecursive(node.children, currentNode, selectedCategories),
      };
    }
    return node;
  });
};

export const onTreeNodeChanged = (
  currentNode: TreeNodeProps,
  selectedCategories: Category[],
  callback: (value: React.SetStateAction<TreeNode[]>) => void
) => {
  callback((previous) => {
    return updateTreeNodeRecursive(previous, currentNode, selectedCategories);
  });
};
