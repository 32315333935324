import { Switch } from '@contentful/f36-components';
import { ChangeEvent } from 'react';

interface UseOldScopeProps {
  useOldScope: boolean;
  onChangeUseOldScope: (useOldScope: boolean) => void;
}

export const UseOldScopeSwitch = (props: UseOldScopeProps): React.JSX.Element => {
  const handleChangeUseOldScope = (switchChecked: ChangeEvent<HTMLInputElement>) => {
    const newValue = switchChecked.currentTarget.checked;
    props.onChangeUseOldScope(newValue);
  };

  return (
    <Switch isChecked={props.useOldScope} onChange={handleChangeUseOldScope}>
      Use old scope
    </Switch>
  );
};
