import { configuration } from '../configuration';
import { ClientResult } from './ClientResult';

export interface HierarchyItem {
  name: string;
  urlSegment: string;
  externallyMarketable: boolean;
  id: string;
  type: 'category' | 'product';
  parentId?: string;
}

export interface HierachyNode {
  item: HierarchyItem;
  children: HierachyNode[];
}

export async function getHierarchyTree(locale: string): Promise<ClientResult<HierachyNode[]>> {
  const url = `${configuration.urls.PRODUCT_HIERARCHY_URL}v3/tree/cached/${configuration.vistaprintTenant}/${locale}?requestor=${configuration.requestor}`;
  const result: Response = await fetch(url);

  if (!result.ok) {
    return {
      failed: true,
      result: [],
    };
  }

  return {
    failed: false,
    result: (await result.json()) as HierachyNode[],
  };
}
