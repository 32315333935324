import { Flex, Note } from '@contentful/f36-components';
import underConstruction from '../assets/rotating_triangle.gif';
import React from 'react';

const UnderDevelopmentWarning = (): React.JSX.Element => {
  return (
    <Flex flexDirection="row" marginBottom="spacingM">
      <img src={underConstruction} alt="loading..." />
      <Note variant="warning">
        Under development. No effect on current pages. For more infomation,{' '}
        <a
          href="https://vistaprint.atlassian.net/wiki/spaces/PDT/pages/3750068704/PLP+Tooling+improvements+Scope"
          target="_blank"
          rel="noreferrer"
        >
          click here
        </a>
      </Note>
      <img src={underConstruction} alt="loading..." />
    </Flex>
  );
};

export default UnderDevelopmentWarning;
