import { Pill } from '@contentful/f36-components';
import React from 'react';
import { TestIds } from '../fixtures/constants';

export const AttributeValuesPills = ({
  attributeName,
  selectedValues,
  handleRemoveValue,
}: {
  attributeName: string;
  selectedValues: string[];
  handleRemoveValue: (attributeName: string, value: string) => void;
}): React.JSX.Element => {
  const handleOnPillClose = (value: string) => {
    handleRemoveValue(attributeName, value);
  };

  return (
    <>
      {selectedValues
        .sort((a, b) => a.localeCompare(b))
        .map((value) => (
          <Pill
            key={value}
            label={value}
            onClose={() => handleOnPillClose(value)}
            style={{ margin: '4px' }}
            data-test-id={TestIds.ATTRIBUTE_VALUE_PILL}
          />
        ))}
    </>
  );
};
