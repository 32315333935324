import { Autocomplete, Stack } from '@contentful/f36-components';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { MsxProduct } from '../clients/MsxService';
import { TestIds } from '../fixtures/constants';
import { ProductPills } from './ProductPills';
import { DataNotFound } from './ErrorNotFoundData';
import { EMPTY_PRODUCT } from '../constants';

interface ProductsSelectorProps {
  onSelectProduct: (product: MsxProduct) => void;
  onRemoveProduct: (product: MsxProduct) => void;
  selectedProducts: MsxProduct[];
  products: MsxProduct[];
  locale: string;
  isLoading: boolean;
}

const ProductsSelector = (props: ProductsSelectorProps): React.JSX.Element => {
  const [displayedProducts, setDisplayedProducts] = useState<MsxProduct[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<MsxProduct[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedProduct, setSelectedProduct] = useState<MsxProduct>(EMPTY_PRODUCT);

  const removeSelectedProducts = (products) => {
    return products.filter((item) => props.selectedProducts.every((selected) => selected.mpvId !== item.mpvId));
  };

  const hasProductsInitialized = () => {
    return props.products && props.products.length > 0;
  };

  useEffect(() => {
    const initializeDisplayedProducts = async () => {
      if (!hasProductsInitialized()) {
        setIsDisabled(true);
      } else {
        const productsWithoutSelection = removeSelectedProducts(props.products);
        setFilteredProducts(productsWithoutSelection);
        setDisplayedProducts(productsWithoutSelection);
        setIsDisabled(false);
      }
    };

    initializeDisplayedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.products)]);

  useEffect(() => {
    const updatedFilteredProducts = displayedProducts.filter((item) =>
      item.mpvId.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredProducts(updatedFilteredProducts);
  }, [inputValue, displayedProducts]);

  const handleSelectItem = (item: MsxProduct): void => {
    setSelectedProduct(item);
    const newFilteredProducts = displayedProducts?.filter((product) => product.mpvId !== item.mpvId);
    setFilteredProducts(newFilteredProducts);
    setDisplayedProducts(newFilteredProducts);
    props.onSelectProduct(item);
  };

  const handleRemoveProduct = (item: MsxProduct) => {
    props.onRemoveProduct(item);
    setSelectedProduct(EMPTY_PRODUCT);
    if (hasProductsInitialized()) {
      const newFilteredProducts = [...displayedProducts, item].sort((a, b) => a.mpvId.localeCompare(b.mpvId));
      setFilteredProducts(newFilteredProducts);
      setDisplayedProducts(newFilteredProducts);
    }
  };

  const handleInputValueChange = (value: string) => {
    setInputValue(value);
  };

  return (
    <Stack flexDirection="column" alignItems="end" data-test-id={TestIds.PRODUCTS_SELECTOR}>
      <Autocomplete
        items={filteredProducts}
        onInputValueChange={handleInputValueChange}
        onSelectItem={handleSelectItem}
        isLoading={props.isLoading}
        textOnAfterSelect="clear"
        closeAfterSelect={false}
        itemToString={(item) => item.mpvId}
        renderItem={(item) => `${item.mpvId} (${item.productKey})`}
        isDisabled={isDisabled}
        selectedItem={selectedProduct}
      />
      {!props.isLoading && !hasProductsInitialized() && <DataNotFound filter={'products'} />}
      <ProductPills selectedProducts={props.selectedProducts} handleRemoveProduct={handleRemoveProduct} />
    </Stack>
  );
};

export default ProductsSelector;
