import { ScopeDefinitionForLocale } from 'src/model/ScopeDefinition';
import { configuration } from '../configuration';
import { ClientResult } from './ClientResult';

export interface Attribute {
  name: string;
  values: string[];
}

export async function getAttributesWithValues(): Promise<ClientResult<Attribute[]>> {
  const url = `${configuration.urls.DPS_URL}v1/attributesWithValues/vistaprint?requestor=${configuration.requestor}`;
  const result: Response = await fetch(url);

  if (!result.ok) {
    return {
      failed: true,
      result: [],
    };
  }

  const attributes = (await result.json()) as Record<string, string[]>;
  const typedAttributes: Attribute[] = [];

  for (const [key, value] of Object.entries(attributes)) {
    typedAttributes.push({
      name: key,
      values: value,
    });
  }

  return {
    failed: false,
    result: typedAttributes,
  };
}

export interface ScopeResult {
  scope: string;
}

export async function buildAlgoliaFilter(
  scopeDefinition: ScopeDefinitionForLocale,
  locale: string,
  token: string
): Promise<ClientResult<string>> {
  const url = `${configuration.urls.DPS_URL}v1/scope/transformation/vistaprint/${locale}?requestor=${configuration.requestor}`;

  try {
    const result: Response = await fetch(url, {
      body: JSON.stringify(scopeDefinition),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    if (result.ok) {
      const scopeResult = (await result.json()) as ScopeResult;
      return { failed: false, result: scopeResult.scope };
    }
  } catch (error) {
    console.error('Failed to build Algolia filter', error);
  }

  return { failed: true, result: null };
}
