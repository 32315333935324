import React from 'react';
import { Paragraph } from '@contentful/f36-components';

const Field = () => {
  /*
     To use the cma, inject it as follows.
     If it is not needed, you can remove the next line.
  */
  // const cma = useCMA();
  // If you only want to extend Contentful's default editing experience
  // reuse Contentful's editor components
  // -> https://www.contentful.com/developers/docs/extensibility/field-editors/
  return <Paragraph>The info is in a different tab</Paragraph>;
};

export default Field;
