import { configuration } from '../configuration';
import { ClientResult } from './ClientResult';

export interface MsxProduct {
  productKey: string;
  mpvId: string;
}

export async function getProducts(locale: string): Promise<ClientResult<MsxProduct[]>> {
  const url = `${configuration.urls.MSX_URL}api/v2/tenant/${configuration.vistaprintTenant}/culture/${locale}/mpvs?requestor=${configuration.requestor}&allAvailabilities=false`;
  const result: Response = await fetch(url);

  if (!result.ok) {
    return {
      failed: true,
      result: [],
    };
  }

  return {
    failed: false,
    result: (await result.json()) as MsxProduct[],
  };
}
