import React from 'react';
import { Box, Note } from '@contentful/f36-components';
import { TestIds } from '../fixtures/constants';

export const ErrorDPSNotWorking = (): React.JSX.Element => {
  return (
    <Box marginBottom="spacingM">
      <Note variant="negative" data-test-id={TestIds.ERROR_RETRIEVING_DATA}>
        {`Error when retrieving algolia filter. The preview won't be updated. Please reload the page after a couple of minutes and, if the
        issue continues, contact us at`}{' '}
        <a href="https://vistaprint.slack.com/archives/C038W3F3CAV" target="_blank" rel="noreferrer">
          #help-discovery-pages
        </a>{' '}
        in Slack
      </Note>
    </Box>
  );
};
