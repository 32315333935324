export const configuration = {
  urls: {
    PRODUCT_HIERARCHY_URL: 'https://product-hierarchy.prod.merch.vpsvc.com/',
    MSX_URL: 'https://merchandising-site-experience.prod.merch.vpsvc.com/',
    DPS_URL: 'https://discovery-pages-service.productdiscovery.vpsvc.com/',
    ATTRIBUTE_MAPPINGS_URL: 'https://attribute-mappings.productdiscovery.vpsvc.com/',
  },
  requestor: 'plp-scope-definition',
  vistaprintTenant: 'vistaprint',
};
