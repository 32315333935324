import { Attribute } from '../clients/DiscoveryPagesService';
import { MsxProduct } from '../clients/MsxService';
import { ApplicationState } from '../model/ApplicationState';
import { Category } from '../model/Category';
import { ScopeDefinition, AttributeFilter, ProductFilter, CategoryFilter } from '../model/ScopeDefinition';

export const mapToApplicationState = (scopeDefinition: ScopeDefinition): ApplicationState => {
  const defaultScope = scopeDefinition.default;

  const applicationState = {
    categories: defaultScope.categories?.map(mapCategory),
    products: defaultScope.products?.map(mapProduct),
    attributes: defaultScope.attributes?.map(mapAttributeFilter),
    useOldScope: defaultScope.useOldScope,
  };

  return applicationState;
};

const mapAttributeFilter = (attributeFilter: AttributeFilter): Attribute => {
  return {
    name: attributeFilter.key,
    values: attributeFilter.selectedValues,
  };
};

const mapCategory = (category: CategoryFilter): Category => {
  const oldCategory = category as any;
  if (isOldFormat(oldCategory)) {
    return {
      id: oldCategory.key,
    };
  }

  return {
    id: category.categoryId,
  };
};

const mapProduct = (product: ProductFilter): MsxProduct => {
  return {
    mpvId: product.value,
    productKey: '',
  };
};

const isOldFormat = (oldCategory: any): boolean => {
  return 'key' in oldCategory;
};
