import React, { useEffect, useState } from 'react';
import { Attribute } from '../clients/DiscoveryPagesService';
import { Autocomplete, Button, Flex, Stack, Text } from '@contentful/f36-components';
import { DeleteIcon } from '@contentful/f36-icons';
import { TestIds } from '../fixtures/constants';
import { AttributeValuesPills } from './AttributeValuesPills';

interface AttributeValueSelectorProps {
  selectedAttribute: Attribute;
  attributeValues: string[];
  onSelectedValuesChange: (attributeName: string, attributeValue: string) => void;
  onRemoveAttribute: (attribute: Attribute) => void;
  onRemoveValue: (attributeName: string, attributeValue: string) => void;
}

export const AttributeValueSelector = (props: AttributeValueSelectorProps): React.JSX.Element => {
  const [displayedValues, setDisplayedValues] = useState<string[]>(props.attributeValues);
  const [filteredValues, setFilteredValues] = useState<string[]>(props.attributeValues);
  const [isDisabled, setIsDisabled] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState('');

  const removeSelectedValues = (values) => {
    return values.filter((value) => props.selectedAttribute.values.every((selected) => selected !== value));
  };

  const hasAttributeValuesInitialized = () => {
    return props.attributeValues && props.attributeValues.length > 0;
  };

  useEffect(() => {
    const initializeDisplayedValues = async () => {
      if (!hasAttributeValuesInitialized()) {
        setIsDisabled(true);
      } else {
        const valuesWithoutSelection = removeSelectedValues(props.attributeValues);
        setDisplayedValues(valuesWithoutSelection);
        setFilteredValues(valuesWithoutSelection);
        setIsDisabled(false);
      }
    };

    initializeDisplayedValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.attributeValues)]);

  useEffect(() => {
    const updatedFilteredValues = displayedValues.filter((attributeValue) =>
      attributeValue.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredValues(updatedFilteredValues);
  }, [inputValue, displayedValues]);

  const handleSelectValue = (value: string): void => {
    setSelectedValue(value);
    const newFilteredValues = displayedValues?.filter((item) => item !== value) ?? [];
    setFilteredValues(newFilteredValues);
    setDisplayedValues(newFilteredValues);
    props.onSelectedValuesChange(props.selectedAttribute.name, value);
  };

  const handleRemoveAttributeValue = (attribute: string, value: string) => {
    setSelectedValue('');
    props.onRemoveValue(attribute, value);
    if (hasAttributeValuesInitialized()) {
      const newFilteredValues = [...displayedValues, value].sort((a, b) => a.localeCompare(b));
      setFilteredValues(newFilteredValues);
      setDisplayedValues(newFilteredValues);
    }
  };

  const handleRemoveAttribute = () => {
    props.onRemoveAttribute(props.selectedAttribute);
  };

  const handleInputValueChange = (value: string) => {
    setInputValue(value);
    if (props.selectedAttribute?.values || value !== '') {
      const updatedFilteredValues = displayedValues?.filter((attributeValue) =>
        attributeValue.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredValues(updatedFilteredValues);
    }
  };

  return (
    <Flex
      data-test-id={TestIds.ATTRIBUTE_VALUE}
      flexDirection="column"
      style={{ border: '1px solid #CFD9E0', borderRadius: '6px' }}
      padding="spacingXs"
      marginBottom="spacingM"
    >
      <Flex justifyContent="space-between" alignItems="center" marginBottom={'spacingS'}>
        <Text fontSize="fontSizeL" fontWeight="fontWeightMedium" style={{ textWrap: 'nowrap', marginRight: '8px' }}>
          {props.selectedAttribute.name}
        </Text>
        <Button variant="negative" size="medium" startIcon={<DeleteIcon />} onClick={handleRemoveAttribute}>
          Remove attribute
        </Button>
      </Flex>
      <Stack marginBottom="spacingS">
        <Autocomplete
          items={filteredValues}
          onSelectItem={handleSelectValue}
          onInputValueChange={handleInputValueChange}
          textOnAfterSelect="clear"
          closeAfterSelect={false}
          isDisabled={isDisabled}
          selectedItem={selectedValue}
        />
      </Stack>
      <Flex flexDirection="row" alignContent="end" justifyContent="end" marginBottom="spacingXs" flexWrap="wrap">
        <AttributeValuesPills
          attributeName={props.selectedAttribute.name}
          selectedValues={props.selectedAttribute.values}
          handleRemoveValue={handleRemoveAttributeValue}
        />
      </Flex>
    </Flex>
  );
};
