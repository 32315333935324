import DropdownTreeSelect, { TreeNodeProps } from 'react-dropdown-tree-select';
import React, { useEffect, useState } from 'react';
import { onTreeNodeChanged } from '../helper/treeNode';
import { TestIds } from '../fixtures/constants';
import { DataNotFound } from './ErrorNotFoundData';
import { Category } from '../model/Category';

interface CategoriesSelectorProps {
  onSelectCategory: (category: Category) => void;
  onRemoveCategory: (category: Category) => void;
  selectedCategories: Category[];
  categories: TreeNodeProps[];
  isLoading: boolean;
  locale: string;
}

export const CategoriesSelector = (props: CategoriesSelectorProps): React.JSX.Element => {
  const [displayedCategories, setDisplayedCategories] = useState<TreeNodeProps[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const hasCategoriesInitialized = () => {
    return props.categories && props.categories.length > 0;
  };

  useEffect(() => {
    const initializeCategoryTree = async () => {
      if (!hasCategoriesInitialized() || props.isLoading) {
        setIsDisabled(true);
      } else {
        loadDisplayedCategories();
        setIsDisabled(false);
      }
    };

    initializeCategoryTree();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.categories)]);

  const fallbackTree: TreeNodeProps[] = props.selectedCategories.map((category) => {
    return {
      value: category.id,
      label: category.id,
      checked: true,
    };
  });

  const loadDisplayedCategories = () => {
    const newDisplayedCategories = JSON.parse(JSON.stringify(props.categories));
    const updateCategories = (nodes: TreeNodeProps[]) => {
      nodes.forEach((node) => {
        node.checked = props.selectedCategories.some((category) => category.id === node.value);
        if (node.children && node.children.length > 0) {
          updateCategories(node.children);
        }
      });
    };
    updateCategories(newDisplayedCategories ?? []);
    setDisplayedCategories(newDisplayedCategories);
  };

  const handleTreeNodeChanged = (currentNode: TreeNodeProps): void => {
    let newSelectedCategories: Category[] = JSON.parse(JSON.stringify(props.selectedCategories));

    if (!displayedCategories) {
      return;
    }

    const category: Category = { id: currentNode.value };

    if (props.selectedCategories.some((item) => item.id === currentNode.value)) {
      newSelectedCategories = newSelectedCategories.filter((c) => c.id !== category.id);
      props.onRemoveCategory(category);
    } else if (currentNode.checked) {
      newSelectedCategories.push(category);
      props.onSelectCategory(category);
    }
    onTreeNodeChanged(currentNode, newSelectedCategories, setDisplayedCategories);
  };

  return (
    <div data-test-id={TestIds.CATEGORIES_SELECTOR}>
      <DropdownTreeSelect
        texts={{ placeholder: 'Select categories' }}
        data={isDisabled ? fallbackTree : displayedCategories}
        onChange={handleTreeNodeChanged}
        onNodeToggle={handleTreeNodeChanged}
        inlineSearchInput={true}
        mode="hierarchical"
        showPartiallySelected={true}
        keepChildrenOnSearch={true}
        keepTreeOnSearch={true}
        clearSearchOnChange={true}
        disabled={isDisabled}
      />
      {!props.isLoading && !hasCategoriesInitialized() && <DataNotFound filter={'categories'} />}
    </div>
  );
};
