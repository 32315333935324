import { useEffect, useState } from 'react';
import { getHierarchyTree, HierachyNode } from '../clients/ProductHierarchyService';
import { TreeNodeProps } from 'react-dropdown-tree-select';
import { ClientResult } from '@clients/ClientResult';

export default function useCategories(locale: string) {
  const [categories, setCategories] = useState<TreeNodeProps[]>([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState<boolean>(false);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        setIsLoadingCategories(true);
        const hierarchyTree: ClientResult<HierachyNode[]> = await getHierarchyTree(locale);
        if (hierarchyTree.failed) {
          console.error('Error in useCategories: Failed to fetch the hierarchy tree');
          return;
        }
        const mappedCategories: TreeNodeProps[] = hierarchyTree.result.map((treeNode) => mapCategoryTreeNode(treeNode));
        setCategories(mappedCategories);
      } catch (error) {
        console.error('Error in useCategories :', error?.message);
      } finally {
        setIsLoadingCategories(false);
      }
    };
    loadCategories();
  }, [locale]);

  return { categories, isLoadingCategories };
}

const mapCategoryTreeNode = (source: HierachyNode): TreeNodeProps => {
  const item = source.item;

  const treeNode: TreeNodeProps = {
    label: item.name,
    value: item.id,
    checked: false,
    expanded: false,
    children: source.children
      .filter((treeNode) => treeNode.item.type === 'category' && treeNode.item.externallyMarketable)
      .map((children) => mapCategoryTreeNode(children)),
  };
  return treeNode;
};
