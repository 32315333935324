import React, { useEffect, useState } from 'react';
import { Attribute } from '../clients/DiscoveryPagesService';
import { Autocomplete, Stack } from '@contentful/f36-components';
import { AttributeValueSelector } from './AttributeValueSelector';
import { TestIds } from '../fixtures/constants';
import { DataNotFound } from './ErrorNotFoundData';
import { EMPTY_ATTRIBUTE } from '../constants';

interface AttributesSelectorProps {
  onSelectAttribute: (attributeName: string) => void;
  onRemoveAttribute: (attributeName: string) => void;
  onSelectAttributeValue: (attributeName: string, attributeValue: string) => void;
  onRemoveAttributeValue: (attributeName: string, attributeValue: string) => void;
  selectedAttributes: Attribute[];
  attributes: Attribute[];
  isLoading: boolean;
  locale: string;
}

export const AttributesSelector = (props: AttributesSelectorProps): React.JSX.Element => {
  const [displayedAttributes, setDisplayedAttributes] = useState<Attribute[]>([]);
  const [filteredAttributes, setFilteredAttributes] = useState<Attribute[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedAttribute, setSelectedAttribute] = useState<Attribute>(EMPTY_ATTRIBUTE);

  const removeSelectedAttributes = (attributes: Attribute[]) => {
    return attributes.filter((item) => props.selectedAttributes.every((selected) => selected.name !== item.name));
  };

  const hasAttributesInitialized = () => {
    return props.attributes && props.attributes.length > 0;
  };

  useEffect(() => {
    const initializeDisplayedAttributes = async () => {
      if (!hasAttributesInitialized()) {
        setIsDisabled(true);
      } else {
        const attributesWithoutSelection = removeSelectedAttributes(props.attributes);
        setFilteredAttributes(attributesWithoutSelection);
        setDisplayedAttributes(attributesWithoutSelection);
        setIsDisabled(false);
      }
    };

    initializeDisplayedAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.attributes)]);

  useEffect(() => {
    const updatedFilteredAttributes = displayedAttributes.filter((item) =>
      item.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredAttributes(updatedFilteredAttributes);
  }, [inputValue, displayedAttributes]);

  const handleSelectAttribute = (attribute: Attribute) => {
    setSelectedAttribute(attribute);
    const newFilteredAttributes = displayedAttributes?.filter((elem) => elem.name !== attribute.name);
    setFilteredAttributes(newFilteredAttributes);
    setDisplayedAttributes(newFilteredAttributes);
    props.onSelectAttribute(attribute.name);
  };

  const handleRemoveAttribute = (attribute: Attribute) => {
    setSelectedAttribute(EMPTY_ATTRIBUTE);
    props.onRemoveAttribute(attribute.name);
    if (hasAttributesInitialized()) {
      const newFilteredAttributes = [...displayedAttributes, attribute].sort((a, b) => a.name.localeCompare(b.name));
      setFilteredAttributes(newFilteredAttributes);
      setDisplayedAttributes(newFilteredAttributes);
    }
  };

  const handleInputAttributeChange = (value: string) => {
    setInputValue(value);
  };

  return (
    <>
      <Stack marginBottom="spacingM" data-test-id={TestIds.ATTRIBUTES_SELECTOR}>
        <Autocomplete
          items={filteredAttributes}
          itemToString={(item) => item.name}
          renderItem={(item) => item.name}
          onInputValueChange={handleInputAttributeChange}
          onSelectItem={handleSelectAttribute}
          textOnAfterSelect="clear"
          closeAfterSelect={false}
          isDisabled={isDisabled}
          isLoading={props.isLoading}
          selectedItem={selectedAttribute}
        />
      </Stack>
      {!props.isLoading && !hasAttributesInitialized() && <DataNotFound filter={'attributes'} />}
      {hasAttributesInitialized() &&
        props.selectedAttributes.map((attribute) => (
          <AttributeValueSelector
            key={attribute.name}
            selectedAttribute={attribute}
            attributeValues={props.attributes.find((elem) => elem.name === attribute.name)?.values}
            onSelectedValuesChange={props.onSelectAttributeValue}
            onRemoveAttribute={handleRemoveAttribute}
            onRemoveValue={props.onRemoveAttributeValue}
          />
        ))}
    </>
  );
};
