import { useEffect, useState } from 'react';
import { ScopeDefinition } from 'src/model/ScopeDefinition';
import { buildAlgoliaFilter } from '../clients/DiscoveryPagesService';
import { EMPTY_SCOPE_DEFINITION } from '../constants';

export default function useAlgoliaFilter(authToken: string, scopeDefinition: ScopeDefinition, locale: string) {
  const [algoliaFilter, setAlgoliaFilter] = useState<string>('');
  const [errorGeneratingAlgoliaFilter, setErrorGeneratingAlgoliaFilter] = useState<boolean>(false);

  useEffect(() => {
    const generateAlgoliaFilter = async () => {
      try {
        setErrorGeneratingAlgoliaFilter(false);

        if (hasEmptyScope(scopeDefinition)) {
          setAlgoliaFilter('');
          return;
        }

        if (!authToken) {
          setErrorGeneratingAlgoliaFilter(true);
          return;
        }

        const filter = await buildAlgoliaFilter(scopeDefinition.default, locale, authToken);
        if (filter.failed) {
          console.error('Error in useAlgoliaFilter');
          setErrorGeneratingAlgoliaFilter(true);
          setAlgoliaFilter('');
          return;
        }
        setAlgoliaFilter(filter.result);
      } catch (error) {
        console.error('Error in useAlgoliaFilter: ', error?.message);
        setErrorGeneratingAlgoliaFilter(true);
      }
    };

    generateAlgoliaFilter();
  }, [scopeDefinition, authToken, locale]);

  return { algoliaFilter, errorGeneratingAlgoliaFilter };
}

const hasEmptyScope = (scopeDefinition: ScopeDefinition) => {
  return JSON.stringify(scopeDefinition) === JSON.stringify(EMPTY_SCOPE_DEFINITION);
};
