import { useEffect, useState } from 'react';
import { getProducts, MsxProduct } from '../clients/MsxService';

export default function useProducts(locale: string) {
  const [products, setProducts] = useState<MsxProduct[]>([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(false);

  const sortByMpvIdAlphabetic = (a: MsxProduct, b: MsxProduct): number => {
    return a.mpvId.localeCompare(b.mpvId);
  };

  useEffect(() => {
    const loadProducts = async () => {
      try {
        setIsLoadingProducts(true);
        const products = await getProducts(locale);
        const sortedProducts = products.result.sort(sortByMpvIdAlphabetic);
        setProducts(sortedProducts);
      } catch (error) {
        console.error('Error in useProducts: Failed to fetch products from MSXService - ', error?.message);
      } finally {
        setIsLoadingProducts(false);
      }
    };
    loadProducts();
  }, [locale]);

  return { products, isLoadingProducts };
}
