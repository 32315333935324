import { useEffect, useState } from 'react';
import { Attribute, getAttributesWithValues } from '../clients/DiscoveryPagesService';
import { AttributeMapping, getAttributeMappings } from '../clients/AttributeMappingsService';

export default function useAttributes() {
  const [mappedAttributes, setMappedAttributes] = useState<Attribute[]>([]);
  const [isLoadingAttributes, setIsLoadingAttributes] = useState<boolean>(false);

  useEffect(() => {
    const getAttributesAndApplyMappings = async () => {
      try {
        setIsLoadingAttributes(true);

        const [allAttributes, attributeMappings] = await Promise.all([
          getAttributesWithValues(),
          getAttributeMappings(),
        ]);

        if (allAttributes.failed || attributeMappings.failed) {
          console.error(
            `Error in useAttributes: ${allAttributes.failed ?? 'Failed to fetch attributes'}  ${
              attributeMappings.failed ?? 'Failed to map attributes'
            }`
          );
          return;
        }

        const result = applyAttributeMappings(allAttributes.result, attributeMappings.result);
        setMappedAttributes(result);
      } catch (error) {
        console.error('Error in useAttributes: ', error?.message);
      } finally {
        setIsLoadingAttributes(false);
      }
    };
    getAttributesAndApplyMappings();
  }, []);

  return { mappedAttributes, isLoadingAttributes };
}
const applyAttributeMappings = (allAttributes: Attribute[], attributeMappings: AttributeMapping[]): Attribute[] => {
  const result: Attribute[] = [];
  for (const attribute of allAttributes) {
    const attributeMapping = attributeMappings.find((a) => a.attribute.source === attribute.name);
    if (attributeMapping) {
      result.push(applyValueMappings(attribute, attributeMapping));
    } else {
      result.push(attribute);
    }
  }

  return result;
};

const applyValueMappings = (attribute: Attribute, attributeMapping: AttributeMapping): Attribute => {
  const mappedValues = new Set<string>();

  for (const value of attribute.values) {
    const valueMapping = attributeMapping.values.find((v) => v.source === value);
    if (valueMapping) {
      mappedValues.add(valueMapping.mapped);
    } else {
      mappedValues.add(value);
    }
  }
  return {
    name: attributeMapping.attribute.mapped,
    values: [...mappedValues],
  };
};
